import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'
import { Cookies } from 'quasar'
import { getCookie, setCookie } from 'src/utils/Cookie'

const PANEL_URL = process.env.VUE_APP_PANEL_URL;

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function ({ store, ssrContext }) {
  const createHistory = process.env.SERVER ? createMemoryHistory : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE)
  })

  Router.beforeEach((to, from, next) => {

    if ((to.path !== '/login' && to.path !== '/login/forgot') && (!store.state.main.user || !store.state.main.user._id || !store.state.main.account || !store.state.main.account._id)) {
      setCookie('preGuard', to.fullPath, 7)
      next('/login')
    } else if (to.path === '/' || to.path === '/*') {
      next('/dashboard')
    } else {
      next()
    }
  })

  return Router
})
