import { boot } from 'quasar/wrappers'

export default boot(({ app }) => {
  app.config.globalProperties.$gb = {
    filter (val, update) {
      try {
        if (val === '') {
          update(() => {
            this.filterAccounts = this.accounts
          })
          return
        }
        update(() => {
          this.filterAccounts = this.accounts.filter(v => {
            return v.label.toLowerCase().includes(val.toLowerCase())
          })
        })
      } catch (e) {
        throw new Error(e)
      }
    }
  }
})
