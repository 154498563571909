import { Cookies } from "quasar";

const PANEL_URL = process.env.VUE_APP_PANEL_URL;

function setCookie(name, value, days) {
    let expires = days??7;
    Cookies.set(name, value, {domain: PANEL_URL, expires});
}

function getCookie(name) {
    const cookie = Cookies.get(name);
    return cookie;
}

function eraseCookie(name) {
    Cookies.remove(name, { domain: PANEL_URL })
}

export {setCookie, getCookie, eraseCookie}